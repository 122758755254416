import { clsx, type ClassValue } from 'clsx';
import { useEffect, useMemo, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Simple debounce implementation
 */
function debounce<Callback extends (...args: Parameters<Callback>) => void>(
  fn: Callback,
  delay: number
) {
  let timer: ReturnType<typeof setTimeout> | null = null;
  return (...args: Parameters<Callback>) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export function useDebounce<
  Callback extends (...args: Parameters<Callback>) => ReturnType<Callback>,
>(callback: Callback, delay: number) {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  });
  return useMemo(
    () =>
      debounce(
        (...args: Parameters<Callback>) => callbackRef.current(...args),
        delay
      ),
    [delay]
  );
}

export function formatDate(data: string | Date, short?: boolean) {
  const year = short ? '2-digit' : 'numeric';
  const month = short ? 'short' : 'long';

  return new Date(data).toLocaleDateString('nl-NL', {
    year,
    month,
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // 24-hour format
  });
}

export function stripHTML(html: string | null) {
  return html ? html.replace(/<[^>]*>/g, '') : '';
}

/**
 * Utility type to modify a type by omitting some keys and adding some new keys
 */
export type Modify<T, R> = Omit<T, keyof R> & R;
